import { computed, ComputedRef, ref, Ref } from 'vue';
import { useLoadingStore } from '@multivac-se/muwo-frontend-shared';
import { LoadingStoreContext } from '@/core/utils/LoadingStoreContext';

export function usePlaceholder(
  loadingStoreContext: LoadingStoreContext | LoadingStoreContext[],
  hasNoData?: Ref<boolean>,
) {
  let isLoading: Ref<boolean> | ComputedRef<boolean> = ref(false);

  if (!Array.isArray(loadingStoreContext)) {
    //eslint-disable-next-line vue/no-ref-as-operand
    isLoading = useLoadingStore().withContext(loadingStoreContext).isLoading();
  } else {
    const loadingStoreRefs = loadingStoreContext.map((context) =>
      useLoadingStore().withContext(context).isLoading(),
    );
    //eslint-disable-next-line vue/no-ref-as-operand
    isLoading = computed(() => loadingStoreRefs.some((ref) => ref.value));
  }

  const showPlaceholder = computed(() => {
    return !!(isLoading.value || hasNoData?.value);
  });

  const placeholderTextKey = computed(() =>
    isLoading.value ? 'WB_LOADING_S40_Z1' : 'WB_NO_DATA_S40_Z1',
  );

  return {
    showPlaceholder,
    placeholderTextKey,
  };
}
