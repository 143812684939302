<script lang="ts" setup>
import { computed } from 'vue';
import {
  S2cTable,
  S2cTableHeaderModel,
  Machine,
  useMachineStore,
} from '@multivac-se/s2-components';
import { getMachinesVNCViewUrl } from '@/domain/vnc/utils/VNCUtils';

const { machines, selectedMachine } = useMachineStore();

function setSelectedMachine(machine: Machine) {
  selectedMachine.value = machine;
}

const headers = computed(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (): Array<S2cTableHeaderModel<any>> => [
    {
      title: 'Machine',
      property: 'machineName',
      isSearchable: true,
      isSortable: true,
    },
    {
      title: 'Alias',
      property: 'displayName',
      isSearchable: true,
      isSortable: true,
    },
    {
      title: 'Customer',
      property: 'customer.name',
      isSearchable: true,
      isSortable: true,
    },
  ],
);
</script>

<template>
  <S2cTable
    :headers="headers"
    :table-data="machines.slice()"
    search-term-context="Machines"
    rows-clickable
    responsive-table-breakpoint="md"
  >
    <template #tableBody="{ rowData }">
      <router-link
        v-slot="{ navigate }"
        :to="getMachinesVNCViewUrl(rowData.machineName)"
        custom
      >
        <tr
          :key="rowData.machineName"
          @click="[setSelectedMachine(rowData), navigate($event)]"
        >
          <td :data-header="headers[0].title">
            <router-link :to="getMachinesVNCViewUrl(rowData.machineName)">
              {{ rowData.machineName }}</router-link
            >
          </td>
          <td :data-header="headers[1].title">
            {{ rowData.displayName }}
          </td>
          <td :data-header="headers[2].title">
            {{ rowData.customer.name }}
          </td>
        </tr>
      </router-link>
    </template>
  </S2cTable>
</template>
